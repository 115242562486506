import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Contact"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "enquiries"
    }}>{`Enquiries`}</h1>
    <p>{`For general enquiries to the College, please call `}<a parentName="p" {...{
        "href": "tel:0395082100"
      }}>{`(03) 9508 2100`}</a>{` or email us on `}<a parentName="p" {...{
        "href": "mailto:enrolment@delasalle.vic.edu.au"
      }}>{`enrolment@delasalle.vic.edu.au`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0f3a08d3416d0d3854f2901e9b5efbc7/4b319/banner-05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAB0qpmbbi0FP/EABoQAAMBAAMAAAAAAAAAAAAAAAABAgMSEyH/2gAIAQEAAQUCvSZmNYZzRVtir3sZ/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAESIf/aAAgBAwEBPwGtKR//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEiH/2gAIAQIBAT8BnEv/xAAZEAACAwEAAAAAAAAAAAAAAAAAARARIUH/2gAIAQEABj8CjHF0jh//xAAbEAADAQADAQAAAAAAAAAAAAABESEAMUFRkf/aAAgBAQABPyGgN81Uq8A4wQsWq+ZTCI8dYsgAeb//2gAMAwEAAgADAAAAEG/v/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhMf/aAAgBAwEBPxBTiA7f/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhQf/aAAgBAgEBPxABqXy//8QAHRABAAICAgMAAAAAAAAAAAAAAQARIVExcUFhsf/aAAgBAQABPxBcvXO0AuLfgMfayBjE8p7ddRiI0XMjUew1gA19n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "banner 05",
            "title": "banner 05",
            "src": "/static/0f3a08d3416d0d3854f2901e9b5efbc7/212bf/banner-05.jpg",
            "srcSet": ["/static/0f3a08d3416d0d3854f2901e9b5efbc7/7809d/banner-05.jpg 192w", "/static/0f3a08d3416d0d3854f2901e9b5efbc7/4ecad/banner-05.jpg 384w", "/static/0f3a08d3416d0d3854f2901e9b5efbc7/212bf/banner-05.jpg 768w", "/static/0f3a08d3416d0d3854f2901e9b5efbc7/5ef17/banner-05.jpg 1152w", "/static/0f3a08d3416d0d3854f2901e9b5efbc7/ac99c/banner-05.jpg 1536w", "/static/0f3a08d3416d0d3854f2901e9b5efbc7/4b319/banner-05.jpg 5472w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      